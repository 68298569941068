:root {

  --body-font: 'Poppins', sans-serif;
  /* TONO 1 */
  --main-color: #1c1c24;
  --secondary-color: #625DEb;

  --text-color-1:#BABFC4;
  --text-color-2:#7C8189;
  --text-color-3:#404347;
  --tertiary:#d75a64;
  
  --color-background:#f3f3f3;
  --color-background-2: rgb(242, 242, 242);

  --main-to-dark-color: var(--main-color);
  --dark-to-main-color: var(--bg-dark);
  --shadow-black-100: 0 3px 5px rgba(0, 0, 0, 0.1);
  --shadow-black-300: 0 5px 15px rgba(0, 0, 0, 0.3);
  --shadow-black-600: 0 5px 15px rgba(0, 0, 0, 0.6);
  --shadow-secondary: 5px 5px 15px rgb(106, 153, 255);
  
  --black-900: #000000;
  --black-400: #666666;
  --black-100: #999999;
  --black-000: #ffffff;
  --black-alpha-100: rgba(0, 0, 0, 0.05);


  --ion-color-primary: #1c1c24;
  --ion-color-primary-rgb: 28,28,36;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255,255,255;
  --ion-color-primary-shade: #191920;
  --ion-color-primary-tint: #33333a;

  --ion-color-secondary: #625DEb;
  --ion-color-secondary-rgb: 98,93,235;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255,255,255;
  --ion-color-secondary-shade: #5652cf;
  --ion-color-secondary-tint: #726ded;

  --ion-color-tertiary: #0090a3;
  --ion-color-tertiary-rgb: 0,144,163;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255,255,255;
  --ion-color-tertiary-shade: #007f8f;
  --ion-color-tertiary-tint: #1a9bac;

  --ion-color-success: #1c1c24;
  --ion-color-success-rgb: 0,144,163;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255,255,255;
  --ion-color-success-shade: #007f8f;
  --ion-color-success-tint: #1a9bac;

  --ion-color-warning: #ffc409;
  --ion-color-warning-rgb: 255,196,9;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0,0,0;
  --ion-color-warning-shade: #e0ac08;
  --ion-color-warning-tint: #ffca22;

  --ion-color-danger: #eb445a;
  --ion-color-danger-rgb: 235,68,90;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255,255,255;
  --ion-color-danger-shade: #cf3c4f;
  --ion-color-danger-tint: #ed576b;

  --ion-color-dark: #222428;
  --ion-color-dark-rgb: 34,36,40;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255,255,255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;

  --ion-color-medium: #92949c;
  --ion-color-medium-rgb: 146,148,156;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255,255,255;
  --ion-color-medium-shade: #808289;
  --ion-color-medium-tint: #9d9fa6;

  --ion-color-light: #f4f5f8;
  --ion-color-light-rgb: 244,245,248;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0,0,0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;

}